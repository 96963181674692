import axios from 'axios';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { UserIdentity } from '../../api/contract/userIdentity';
import { useError } from '../errors/ErrorProvider';
import { UserIdentityContext } from './UserIdentityProvider.types';

const Context = createContext<UserIdentityContext>(undefined!);

export function UserIdentityProvider(props: PropsWithChildren<{}>): JSX.Element {
	const { children } = props;
	const { errorLevel } = useError();
	const { data: response, isError, isLoading, refetch } = useQuery([errorLevel], () => axios.get<UserIdentity>('/api/user'));
	const data = response?.data;

	const isAuthenticated = !isError && !isLoading;
	const identity = useMemo(
		() => ({
			identity: data ?? null,
			isUserError: !!data && isAuthenticated && (!data.email || !data.isEmailVerified),
			isLoading,
			roles: new Set(data?.roles),
			isAuthenticated,
			reload: () => refetch(),
		}),
		[isLoading, refetch, isAuthenticated, data],
	);

	return <Context.Provider value={identity}>{children}</Context.Provider>;
}

export function useUserIdentity(): UserIdentityContext {
	const contextData = useContext(Context);

	if (contextData === undefined) {
		throw new Error(`${useUserIdentity.name} should be used only inside ${UserIdentityProvider.name}`);
	}

	return contextData;
}
