import { useOutlet } from 'react-router-dom';
import { Forbidden } from '../../pages/Errors/Forbidden';

import { useUserIdentity } from './UserIdentityProvider';
import { AuthorizedUsersBoundaryProps } from './AuthorizedUsersBoundary.types';

export function AuthorizedUsersBoundary(props: AuthorizedUsersBoundaryProps): JSX.Element {
	const { allowedFor } = props;
	const { roles } = useUserIdentity();
	const outlet = useOutlet();

	return allowedFor.some((x) => roles?.has(x)) ? <>{outlet}</> : <Forbidden />;
}
